import type { Result } from "@frontend/ui-kit/src/types/result";
import { useSafe } from '~/composables/shared/useSafe';
import { useApi } from '~/composables/shared/useApi';
import type {
    IMortgageCondition,
    IInstallmentCondition,
    PurchaseCondition,
    IPurchaseListItem,
    IBookingMortgageFilter,
    IBookingInstallmentFilter,
    IBookingPeriodsData,
} from '~/layers/booking/types/purchase-condition';
import priceHelper from "@frontend/ui-kit/src/utils/priceHelper";
import type { MortgageType } from "~/layers/booking/types/bookingMortgage";
import { PurchaseConditions } from "~/layers/booking/constants/purchase-condition";
import type { InstallmentPaymentType } from "~/layers/booking/types/bookingInstallment";

export const useBookingPurchaseCondition = () => {
    const { get, post } = useApi();
    const { tryAction, trySilentAction } = useSafe();

    const { t } = useI18n();
    const config = useRuntimeConfig();

    /**
    * Получает информацию об ипотеке
    * @param realtyId идентификатор объекта
    * @param realtyPrice стоимость объекта
    * @param mortgageProgramType тип ипотечной программы
    * @param initialPayment процент первоначального взноса
    * @param periodInYears срок ипотеки
    */
    const getMortgageData = async (filter: IBookingMortgageFilter) : Promise<Result<IMortgageCondition>> => {
        const queryString = mortgageBuildQueryParams(
            filter.realtyId,
            filter.realtyPrice,
            filter.mortgageProgramType,
            filter.initialPayment,
            filter.periodInYears
        );

        return await tryAction(async () => {
            const url = `${config.public.calculatorApiBaseUrl}/mortgage/summary?${queryString}`;
            return await get(url);
        });
    };

    /**
    * Получает информацию о рассрочке
    * @param filter Фильтр
    */
    const getInstallmentData = async (filter: IBookingInstallmentFilter) : Promise<Result<IInstallmentCondition>> => {
        const queryString = installmentBuildQueryParams(
            filter.realtyId,
            filter.realtyPrice,
            filter.installmentRealtyType,
            filter.initialPayment,
            filter.periodInMonths,
            filter.installmentExternalId,
            filter.installmentPaymentType,
        );

        return await trySilentAction(async () => {
            const url = `${config.public.calculatorApiBaseUrl}/installment/summary?${queryString}`;
            return await get(url);
        });
    };

    /**
    * Генерирует query параметры для запроса getPurchaseConditions
    * @param realtyId идентификатор объекта
    * @param realtyPrice стоимость объекта
    * @param mortgageProgramType тип ипотечной программы
    * @param initialPayment процент первоначального взноса
    * @param periodInYears срок ипотеки
    */
    const mortgageBuildQueryParams = (
        realtyId: number,
        realtyPrice: number,
        mortgageProgramType?: string | undefined,
        initialPayment?: number | undefined,
        periodInYears?: number | undefined
    ): string => {
        const params: Record<string, string> = {
            realtyId: realtyId.toString(),
            realtyPrice: realtyPrice.toString(),
        };

        if (mortgageProgramType !== undefined) {
            params.MortgageProgramType = mortgageProgramType;
        }
        if (initialPayment !== undefined) {
            params.InitialPayment = initialPayment.toString();
        }
        if (periodInYears !== undefined) {
            params.PeriodInYears = periodInYears.toString();
        }

        return new URLSearchParams(params).toString();
    };

    /**
    * Генерирует query параметры для запроса getPurchaseConditions
    * @param realtyId идентификатор объекта
    * @param realtyPrice стоимость объекта
    * @param installmentReltyType тип объекта рассрочки
    * @param initialPayment процент первоначального взноса
    * @param periodInYears срок рассрочки
    * @param installmentExternalId идентификатор объекта
    */
    const installmentBuildQueryParams = (
        realtyId: number,
        realtyPrice: number,
        installmentReltyType: string,
        initialPayment: number,
        periodInYears: number,
        installmentExternalId?: string | undefined,
        installmentPaymentType?: InstallmentPaymentType | undefined,
    ): string => {
        const params: Record<string, string> = {
            realtyId: realtyId.toString(),
            realtyPrice: realtyPrice.toString(),
            installmentRealtyType: installmentReltyType,
            initialPayment: initialPayment.toString(),
            periodInMonths: periodInYears.toString(),
        };

        if (installmentExternalId !== undefined) {
            params.InstallmentExternalId = installmentExternalId;
        }

        if (installmentPaymentType !== undefined) {
            params.InstallmentPaymentType = installmentPaymentType;
        }

        return new URLSearchParams(params).toString();
    };

    /**
    * Сохраняет выбор способа покупки.
    * @param bookingId идентификатор бронирования.
    * @param purchaseCondition Способ покупки.
    * @param mortgageProgramType Тип ипотечной программы.
    * @param installmentExternalId Идентификатор выбранной рассрочки.
    * @param realtyPrice Измененная цена ОН.
    */
    const savePurchaseCondition = async (
        bookingId: number, 
        purchaseCondition: PurchaseCondition, 
        mortgageProgramType: MortgageType | null, 
        installmentExternalId: string | null,
        installmentPaymentType: InstallmentPaymentType | null,
        realtyPrice: number | null
    ) : Promise<Result<void>> => {
        const params = {
            purchaseCondition: purchaseCondition, 
            mortgage: createMortgageParams(purchaseCondition, mortgageProgramType),
            installment: createInstallmentParams(purchaseCondition, installmentExternalId, installmentPaymentType),
            realtyPrice: realtyPrice
        } 

        return await tryAction(async () => {
            const url = `${config.public.bookingApiBaseUrl}/booking/${bookingId}/purchase/condition`;
            return await post(url, params); 
        });
    };

    /**
    * Формирует объект, передаваемый для сохранения способа покупки, для ипотеки
    * @param purchaseCondition способ оплаты
    * @param mortgageProgramType тип ипотечной программы
    */
    const createMortgageParams = (purchaseCondition: PurchaseCondition, mortgageProgramType: MortgageType | null) => {
        if (purchaseCondition !== PurchaseConditions.Mortgage) {
            return null;
        }
        return {
            programType: mortgageProgramType,
        }
    }
    
    /**
    * Формирует объект, передаваемый для сохранения способа покупки, для рассрочки
    * @param purchaseCondition способ оплаты
    * @param installmentExternalId идентификатор выбранной рассрочки
    * @param installmentPaymentType тип оплаты рассрочки
    */
    const createInstallmentParams = (
        purchaseCondition: PurchaseCondition, 
        installmentExternalId: string | null,
        installmentPaymentType: InstallmentPaymentType | null,
    ) => {
        if (purchaseCondition !== PurchaseConditions.Installment) {
            return null;
        }
        return {
            externalId: installmentExternalId,
            paymentType: installmentPaymentType,
        }
    }

    /**
    * Получает сроки бронирования
    * @param bookingId идентификатор бронирования.
    */
    const getBookingPeriods = async (bookingId: number) : Promise<Result<IBookingPeriodsData[]>> => {
        return await tryAction(async () => {
            const url = `${config.public.bookingApiBaseUrl}/booking/${bookingId}/purchase/condition/details`;
            return await get(url);
        });
    };

    /**
    * Создает массив для списка подробностей ипотеки
    * @param mortgage информация по ипотеке
    */
    const createMortgageList = (mortgage: IMortgageCondition): IPurchaseListItem[] => {
        if (!mortgage) {
            return [];
        }

        return [
            {
                icon: 'ruble',
                text: t('booking.client.purchase_conditions.items.cost'),
                value: priceHelper.toStringWithRuble(mortgage.increasedCost),
            },
            {
                icon: 'ri-calendar-line',
                text: t('booking.client.purchase_conditions.items.creditPeriod'),
                value: `${t('forms.shared.prepositions.until')} ${mortgage.maxCreditPeriod} ${t('forms.shared.period.years').toLowerCase()}`,
            },
            {
                icon: 'ri-firebase-line',
                text: t('booking.client.purchase_conditions.items.initialPayment'),
                value: `${t('forms.shared.prepositions.from_1')} ${priceHelper.dotToComma(mortgage.minInitialPaymentPercent)}%`,
            },
            {
                icon: 'ri-flashlight-line',
                text: t('booking.client.purchase_conditions.items.rate'),
                value: `${priceHelper.dotToComma(mortgage.rate)}%`,
            },
        ]
    };

    /**
    * Создает массив для списка подробностей рассрочки
    * @param installment информация по рассрочке
    * @param realtyPrice стоимость ОН
    */
    const createInstallmentList = (installment: IInstallmentCondition, realtyPrice: number): IPurchaseListItem[] => {
        if (!installment) {
            return [
                {
                    icon: 'ruble',
                    text: t('booking.client.purchase_conditions.items.cost'),
                    value: priceHelper.toStringWithRuble(realtyPrice),
                }
            ];
        }

        return [
            {
                icon: 'ruble',
                text: t('booking.client.purchase_conditions.items.cost'),
                value: priceHelper.toStringWithRuble(installment.totalRealtyCost),
            },
            {
                icon: 'ri-calendar-line',
                text: t('booking.client.purchase_conditions.items.creditPeriod'),
                value: `${t('forms.shared.prepositions.until')} ${installment.maxInstallmentPeriod} ${t('forms.shared.period.month_short').toLowerCase()}`,
            },
            {
                icon: 'ri-firebase-line',
                text: t('booking.client.purchase_conditions.items.initialPayment'),
                value: `${t('forms.shared.prepositions.from_1')} ${priceHelper.dotToComma(installment.minInitialPaymentPercent)}%`,
            }
        ]
    };

    /**
    * Создает массив для списка подробностей полной оплаты
    * @param realtyPrice стоимость ОН
    */
    const createFullPaymentList = (realtyPrice: number): IPurchaseListItem[] => {
        if (!realtyPrice) {
            return [];
        }

        return [{
            icon: 'ruble',
            text: t('booking.client.purchase_conditions.items.cost'),
            value: priceHelper.toStringWithRuble(realtyPrice),
        }]
    };

    /**
    * Склоняет "День" в зависимости от выводимого числа
    * @param days количество дней
    */
    const createDayText = (days: number): string => {
        const lastDigit = days % 10;
        const lastTwoDigits = days % 100;
    
        if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
            return t('forms.shared.period.days');
        }
    
        switch (lastDigit) {
        case 1:
            return t('forms.shared.period.day');
        case 2:
        case 3:
        case 4:
            return t('forms.shared.period.day_genitive');
        default:
            return t('forms.shared.period.days');
        }
    };

    return {
        getMortgageData,
        getInstallmentData,
        getBookingPeriods,
        savePurchaseCondition,
        createMortgageList,
        createInstallmentList,
        createFullPaymentList,
        createDayText,
        createMortgageParams,
        createInstallmentParams,
    };
};
